@font-face {
  font-family: "VerelaRound-Regular";
  src: url("./VarelaRound-Regular.ttf") format("truetype");
}


@font-family: "VerelaRound-Regular", "Arial", "Segoe UI", "Tahoma", sans-serif;


body {
  font-family: @font-family;
  margin: 0;

  .light-mode {
    --accent-color: #f59e0b;
    --special-accent-color: #f7f827; // for dark backgrounds
    --primary-color: #4a90e2; // A calming blue
    --secondary-color: #f4f4f4; // Light background
    --text-color: #333333; // Dark grey for text
    --background-color: transparent;
    --opaque-background-color: rgba(255, 255, 255, 1);
    --special-color: blueviolet;
    --special-color2: #31ab98;
    --table-border-color: #ddd;
    --table-header-bg: rgba(149, 154, 241, 0.64);
    --table-header-color: #333;
    --table-row-bg: #fff;
    --table-row-alt-bg: #f9f9f9;
    --table-font-color: #333;
    --table-padding: 10px;

  }

  .dark-mode {
    --accent-color: rgba(149, 154, 241, 0.64); // Orange
    --primary-color: #6a90e2; // A calming blue
    --secondary-color: #44f4f4; // Light background
    --text-color: #f4f4f4; // Dark grey for text
    --background-color: #333333;
    --opaque-background-color: #222222ff;
    --special-color: blueviolet;
    --special-color2: #31ab98;
    --table-border-color: #ddd;
    --table-header-bg: rgba(149, 154, 241, 0.64);
    --table-header-color: #333;
    --table-row-bg: #fff;
    --table-row-alt-bg: #f9f9f9;
    --table-font-color: #333;
    --table-padding: 10px;


  }

  --standard-space: 40px;
  @media (max-width: 1000px) {
    font-size: 3vw;
    --standard-space: 20px;
  }

}

h1, h2, h3 {
  color: var(--primary-color);
  font-family: @font-family;
  margin-bottom: 10px;
  font-weight: bold;
}


// Mixins
.flex-container() {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-shadow() {
  box-shadow: 5px 11px 11px 7px rgba(16, 19, 65, 0.5);
  @media (max-width: 1400px) {
    box-shadow: 2px 5px 5px 3px rgba(16, 19, 65, 0.5);
  }
}

.header {
  background-color: var(--primary-color);
  padding: 1em;
  margin-bottom: 1em;
  border-bottom: 5px solid var(--secondary-color);


  .title {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    title {
      color: var(--opaque-background-color);
      display: block;
      font-size: 1.4rem;
      @media (min-width: 1400px ) {
        font-size: 2rem;
      }
    }

    .logo {
      justify-self: end;
      margin-top: -1em;

      img {
        width: auto;
        height: 8em;
      }
    }
  }

  h1 {
    font-size: 1em;
  }

  @media screen and (max-width: 1000px) {
    .logo {
      img {
        width: auto;
        height: 6em;
      }
    }
  }

  h1 {
    font-size: 2rem;
    color: var(--secondary-color);
    font-weight: bold;
  }
}

// Navigation
nav {
  .flex-container();
  font-weight: bold;
  list-style: none;
  justify-content: flex-start;
  border-radius: 5px;
  padding: 0.5em 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  background-color: var(--opaque-background-color);


  li {
    margin: 3px 1em;
    font-size: 1.2rem;
    display: flex;

    a {
      flex-grow: 1;
      color: #333; /* Dark color for text */
      text-decoration: none;
      padding: 5px 10px;
    }

    .selected {
      color: var(--opaque-background-color);
      background-color: var(--primary-color);
    }

    &:hover > ul {
      display: block;
    }

    a {
      text-decoration: none;
      padding: 0.5em 1em;
      border-radius: 4px;

      &:hover {
        background-color: var(--primary-color);
        //opacity: 0.8;
        color: var(--opaque-background-color);
      }
    }
  }

  ul {
    display: none;
    position: absolute;
    //left: 0;
    //top: 100%;
    left: auto;
    margin-top: 2em;
    list-style: none;
    //padding: 0;
    //margin: 0;
    background: var(--opaque-background-color);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-radius: 0.25em;
    border: 1px solid black;

    @media (max-width: 1024px) {
      right: 8em;
    }

    li {
      a {
        padding: 10px 20px;
      }
    }
  }
}

// Main content
.main {
  padding: 1em;
  display: grid;
  flex-direction: column;

  .section {
    margin-bottom: 1em;

    h2 {
      font-size: 1.5em;
      color: var(--secondary-color);
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    p {
      line-height: 1.5;
      font-size: 1em;
      color: var(--primary-color);
    }
  }


}

details {
  background-color: var(--secondary-color);
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  summary {
    color: var(--primary-color);
    font-family: @font-family;
    cursor: pointer;
    outline: none;
    font-weight: bold;

    &:hover {
      color: var(--accent-color);
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  p {
    font-family: @font-family;
    line-height: 1.6;
    margin-top: 10px;
  }
}

a, button {
  color: var(--primary-color);
  transition: color 0.3s ease, transform 0.3s ease, filter 0.3s ease;

  &:hover {

  }
}

@keyframes fadeInWithTransparency {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content {
  animation: fadeInWithTransparency 1s ease-out;
}

.indent {
  padding: 0 3em;
}

button, .button {
  background-color: var(--primary-color);
  color: white;
  padding: 1em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: @font-family;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--accent-color);
  }

  &.close {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 1.2em;
    padding: 2px;
  }
}

.gradient-item(@c) {
  background-size: 200% 200%;
  background-image: linear-gradient(to right, var(--opaque-background-color) 0%, @c 50%, var(--opaque-background-color) 100%);
  animation: GradientAnimated 4s ease-in-out infinite;
}

@keyframes GradientAnimated {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.standard-box() {
  padding: var(--standard-space);
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 1px 1px 4px 1px #00000078;

}

article {
  table {
    border-collapse: collapse;
    box-shadow: 2px 2px 20px 2px silver;
    margin: 20px 0;
    color: var(--table-font-color);

    th, td {
      padding: var(--table-padding);
      border: 1px solid var(--table-border-color);
    }

    th {
      background-color: var(--table-header-bg);
      color: var(--table-header-color);
    }

    tr:nth-child(even) {
      background-color: var(--table-row-alt-bg);
    }

    tr:nth-child(odd) {
      background-color: var(--table-row-bg);
    }

    tr:hover {
      background-color: rgba(0, 0, 0, 0.05); /* Light grey background on hover */
    }

    caption {
      font-size: 1.5em;
      margin: 10px 0;
      text-align: left;
      color: var(--table-header-color);
    }
  }


  animation: fadeInWithTransparency 1s ease-out;

  .standard-box();
  font-size: 1rem;
  color: var(--text-color);
  font-family: @font-family;
  line-height: 1.6;
  width: 100%;
  max-height: 70vh;
  overflow: auto;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.9);

  @media (max-width: 768px) {
    padding: 15px; // Smaller padding on smaller screens
  }

  @media (max-width: 480px) {
    padding: 10px; // Even smaller padding on mobile screens
  }
  @media (min-width: 1200px) {
    max-width: calc(80vw - 10em);
    li {
      margin-bottom: 1em;
    }
  }

  p {
    text-align: justify;
    margin-bottom: 15px;
  }

  .quote {
    font-style: italic;
    font-family: serif;
    margin: 0 4em;
  }

  .box {
    .standard-box();
    margin: 1em;
    background: var(--opaque-background-color);
  }

  img {
    float: left;
    margin-right: var(--standard-space);
    @media (min-width: 1201px) {
      width: 40%;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }

  }

  li {
    font-family: @font-family;
    line-height: 1.6;
    text-align: justify;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

}


ul.checked-list {
  list-style: none; /* Remove default bullets */
  margin-left: 0;
  padding-left: 1em;
  text-indent: -1em; /* Create a hanging indent */

  li:before {
    content: "✓"; /* Unicode character for a checkmark (✓) */
    padding-right: 5px; /* Add spacing between the checkmark and text */
    font-weight: bold;
    color: green;
    border-radius: 1em;
    width: 1em;
    font-size: 2em;
  }

}

.small-self-pic {
  max-width: 10vw;
  min-width: 10em;
}

.liability {
  font-size: 0.7em;
}

.announcement {
  font-style: italic;

  &.regular {
    color: orange;
    font-weight: bolder;
  }

  &.important {
    color: orange;
    font-size: 2em;
    -webkit-text-stroke: 1px #000000;
  }

  &.cancellation {
    color: orange;
    font-weight: bolder;
    font-size: 2em;
  }

  &.danger {
    color: red;
    font-weight: bolder;
    font-size: 1.75em;
  }

}

